.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.h1, h1 {
    font-size: 30px;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

.btn-outline-primary-onx{color:#500778;border-color:#500778}
.btn-outline-primary-onx:hover{color:#fff;background-color:#500778;border-color:#500778}
.btn-outline-primary-onx.focus,
.btn-outline-primary-onx:focus{box-shadow:0 0 0 .2rem rgba(80,7,120,.5)}
.btn-outline-primary-onx.disabled,
.btn-outline-primary-onx:disabled{color:#000000;background-color:#FF0000}
.btn-outline-primary-onx:not(:disabled):not(.disabled).active,
.btn-outline-primary-onx:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary-onx.dropdown-toggle{color:#fff;background-color:#500778;border-color:#500778}
.btn-outline-primary-onx:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary-onx:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary-onx.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(80,7,120,.5)}


span.header-text {
    margin-top: 20px;
    margin-bottom: 10px;
    display: block;
}
.align-left {
  display: flex;
  align-items: flex-start;
}
.align-center {
  display: flex;
  flex-wrap: wrap;
}
.popover {
  max-width: none;
}


#add-setup {
  margin-top: 1rem;
}

#locations-list-container {
  border-radius: .3rem;
  margin-top: 1rem;
}

.txt-area-border {
  border: solid 1px #500778;
}

#locations-list {
  border: none;
  outline: none;
  width: 100%;
}
